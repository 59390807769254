import React, { Children, useState } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactSub"
import { TextInput, Button, DatePicker, Select, Check } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    address: yup.string().required(`お客様のご住所を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Sdate: yup.string().required(`配達開始日を指定してください`),
    sub: yup.string().required(`どちらかを選択してください`),
    other: yup.string().when("paper", {
        is: "その他",
        then: yup.string().required(`新聞の銘柄を入力してください`),
    }),
    paper: yup.string().required(`新聞の銘柄を選択してください`),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    address: string
    telephone: string
    email: string
    Sdate: string
    sub: string
    paper: string
    other: string
    plan: string
    chance: string
    message: string
}

const Subscribe = () => {
    const { errors, watch, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const sub = watch("sub")
    const paper = watch("paper")
    // const papercheck = paper.includes("その他")

    const submit = values => {
        console.log(values)
        const Sdate = dateFormat(values.Sdate, "isoDate")
        const GOOGLE_FORM_ACTION = ContactGoogleForm.action
        // CORS対策
        const CORS_PROXY = "https://cros-nikkei.herokuapp.com/"

        const submitParams = new FormData()
        submitParams.append(
            ContactGoogleForm.name,
            values.name1 + " " + values.name2
        )
        submitParams.append(ContactGoogleForm.address, values.address)
        submitParams.append(ContactGoogleForm.telephone, values.telephone)
        submitParams.append(ContactGoogleForm.email, values.email)
        submitParams.append(ContactGoogleForm.sub, values.sub)
        submitParams.append(ContactGoogleForm.paper, values.paper)
        submitParams.append(ContactGoogleForm.Sdate, Sdate)
        submitParams.append(ContactGoogleForm.chance, values.chance)
        submitParams.append(ContactGoogleForm.message, values.message)

        trackPromise(
            axios
                .post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams)
                .then(() => {
                    navigate(`/thanks`)
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods} watch={watch}>
            <h4 className="text-center text-2xl text-color-4">
                お試し・ご購読のお申込み
            </h4>

            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <TextInput
                    label="ご住所  (お間違いを防ぐため、マンション・ビル名の記載もお願い致します)"
                    name="address"
                    footer={errors.address?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <Select
                    label="「お試し」と「ご購読」どちらのお申し込みですか？"
                    name="sub"
                    footer={errors.sub?.message}
                    value={[
                        { id: 1, value: "お試し" },
                        { id: 2, value: "ご購読" },
                    ]}
                />

                <Check
                    label="新聞の種類を選択して下さい"
                    name="paper"
                    footer={errors.paper?.message}
                    value={[
                        { id: 1, value: "日本経済新聞" },
                        { id: 2, value: "日経MJ（月・水・金）" },
                        { id: 3, value: "毎日新聞" },
                        { id: 4, value: "毎日小学生新聞" },
                        { id: 5, value: "東京中日スポーツ" },
                        { id: 6, value: "デイリースポーツ" },
                        { id: 7, value: "スポーツニッポン" },
                    ]}
                />

                {/* {papercheck && (
                    <TextInput
                        label="その他の新聞の銘柄を入力して下さい"
                        name="other"
                        footer={errors.other?.message}
                    />
                )} */}
                <DatePicker
                    label="いつから配達開始しますか？"
                    name="Sdate"
                    type="date"
                    min={new Date()}
                    footer={errors.Sdate?.message}
                />
                <TextInput
                    label="お申し込みのきっかけ(任意)"
                    name="chance"
                    footer={""}
                />
                <p className="text-sm">
                    ※お手元のハガキや印刷物にキャンペーンコードや名称があれば入力してください。
                </p>

                <TextInput label="通信欄" name="message" footer={""} />
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Subscribe
