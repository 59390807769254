export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/1/d/e/1FAIpQLSfZLfbi4daYAYfb91tB7Q8moffyByWdgNhLYfUpkazy9ia0bg/formResponse",

    name: "entry.1798849997",
    Eaddress: "entry.217478547",
    telephone: "entry.168384005",
    email: "entry.1398916480",
    Edate: "entry.446970662",
    move: "entry.1821908391",
    Saddress: "entry.752438600",
    Sdate: "entry.865550338",
    pay: "entry.1515247756",
    other: "entry.1334473782",
    message: "entry.715530369",
}
