export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/1/d/e/1FAIpQLSfTaabJeNzFsAVbF5sUK44djrhY-IRCiOpiBipE9cL5_A2OVA/formResponse",

    name: "entry.1157295761",
    address: "entry.557272837",
    telephone: "entry.856851497",
    email: "entry.731508438",
    sub: "entry.60898545",
    paper: "entry.663851399",
    Sdate: "entry.1714450908",
    chance: "entry.317525967",
    message: "entry.2096275939",
}
