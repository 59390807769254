import React, { Children, useState } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactPause"
import { TextInput, Button, DatePicker, Select } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    address: yup.string().required(`お客様のご住所を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Sdate: yup.string().required(`お休み開始日を指定してください`),
    start: yup.string().required(`朝刊か夕刊どちらかを選択してください`),
    Edate: yup
        .date()
        .when(
            "Sdate",
            (Sdate, schema) =>
                Sdate &&
                schema.min(Sdate, "開始日よりも後の日付を選択してください")
        )
        .required(`お休み終了日を指定してください`),
    end: yup.string().required(`朝刊か夕刊どちらかを選択してください`),
    keep: yup.string().required(`どちらかを選択してください`),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    address: string
    telephone: string
    email: string
    Sdate: string
    start: string
    Edate: string
    end: string
    keep: string
    message: string
}

const Pause = () => {
    const { errors, watch, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const keep = watch("keep")

    const submit = values => {
        const Sdate = dateFormat(values.Sdate, "isoDate")
        const Edate = dateFormat(values.Edate, "isoDate")
        const GOOGLE_FORM_ACTION = ContactGoogleForm.action
        // CORS対策
        const CORS_PROXY = "https://cros-nikkei.herokuapp.com/"

        const submitParams = new FormData()
        submitParams.append(
            ContactGoogleForm.name,
            values.name1 + " " + values.name2
        )
        submitParams.append(ContactGoogleForm.address, values.address)
        submitParams.append(ContactGoogleForm.telephone, values.telephone)
        submitParams.append(ContactGoogleForm.email, values.email)
        submitParams.append(ContactGoogleForm.Sdate, Sdate)
        submitParams.append(ContactGoogleForm.start, values.start)
        submitParams.append(ContactGoogleForm.Edate, Edate)
        submitParams.append(ContactGoogleForm.end, values.end)
        submitParams.append(ContactGoogleForm.keep, values.keep)
        submitParams.append(ContactGoogleForm.message, values.message)

        trackPromise(
            axios
                .post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams)
                .then(() => {
                    navigate(`/thanks`)
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods} watch={watch}>
            <h4 className="text-center text-2xl text-color-4">
                一時配達休止のご連絡
            </h4>

            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <TextInput
                    label="ご住所　(お間違いを防ぐため、マンション・ビル名の記載もお願い致します)"
                    name="address"
                    footer={errors.address?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <DatePicker
                    label="いつから休止しますか？"
                    name="Sdate"
                    type="date"
                    min={new Date()}
                    footer={errors.Sdate?.message}
                />
                <p className="text-sm text-right">
                    <a
                        href="https://www.nikkeipr.co.jp/advertise/press_holiday.html"
                        style={{ color: "blue" }}
                    >
                        新聞休刊日を確認する
                    </a>
                </p>
                <Select
                    label="朝刊と夕刊どちらからですか？"
                    name="start"
                    footer={errors.start?.message}
                    value={[
                        { id: 1, value: "朝刊から" },
                        { id: 2, value: "夕刊から" },
                    ]}
                />
                <DatePicker
                    label="いつまで休止しますか？"
                    name="Edate"
                    type="date"
                    min={
                        methods.getValues("Sdate")
                            ? methods.getValues("Sdate")
                            : new Date()
                    }
                    footer={errors.Edate?.message}
                />
                <p className="text-sm text-right">
                    <a
                        href="https://www.nikkeipr.co.jp/advertise/press_holiday.html"
                        style={{ color: "blue" }}
                    >
                        新聞休刊日を確認する
                    </a>
                </p>
                <Select
                    label="朝刊と夕刊どちらまでですか？"
                    name="end"
                    footer={errors.end?.message}
                    value={[
                        { id: 1, value: "朝刊まで" },
                        { id: 2, value: "夕刊まで" },
                    ]}
                />
                <Select
                    label="休止中の新聞の取り置きについて"
                    name="keep"
                    footer={errors.keep?.message}
                    value={[
                        {
                            id: 1,
                            value: "休み中の新聞は不要",
                        },
                        {
                            id: 2,
                            value: "休み明けにまとめて届ける",
                        },
                    ]}
                />
                {keep === "休み明けにまとめて届ける" && (
                    <p className="text-sm">
                        ※宅配Boxが一杯の場合は持ち帰らせていただきます。
                        <br />
                        <a
                            href="https://www.nikkeipr.co.jp/advertise/press_holiday.html"
                            style={{ color: "blue" }}
                        >
                            新聞休刊日
                        </a>
                        は朝刊配達をお休みさせて頂きますので
                        <br className="hidden sm:block" />
                        休刊日に再開をご希望の場合、おまとめ分は夕刊時の配達になります。
                        <br />
                        その他休止終了日以外のお届けをご希望の場合は、下記通信欄にご記入願います。
                    </p>
                )}
                <TextInput
                    label="通信欄(ご要望など)"
                    name="message"
                    footer={""}
                />
                <p className="text-sm">
                    お急ぎの場合・ご要望がある場合は、できる限りお電話にてご連絡お願い致します。
                </p>
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Pause
