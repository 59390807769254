export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/1/d/e/1FAIpQLSeueXeJ8JxcvcMs-M67xWdO69AUne9OkzgdEyLb3ptkFBEVuQ/formResponse",

    name: "entry.1827674490",
    address: "entry.1384305985",
    telephone: "entry.1971738968",
    email: "entry.379835097",
    Sdate: "entry.1646713334",
    start: "entry.1442801783",
    Edate: "entry.137635270",
    end: "entry.1676068001",
    keep: "entry.238582985",
    message: "entry.1590261171",
}
